<template>
  <div>
    <slot></slot>
    <div class="form-group row">
      <label
        class="col-lg-3 col-form-label text-lg-right"
        :for="`form-builder-label-${index}`"
      >
        Etykieta
      </label>
      <div class="col-lg-9">
        <input
          class="form-control"
          type="text"
          v-model="field.label"
          :id="`form-builder-label-${index}`"
        >
      </div>
    </div>
    <div class="form-group row mt-2">
      <label
        class="col-lg-3 col-form-label text-lg-right"
        :for="`form-builder-help-${index}`"
      >
        Tekst pomocniczy
      </label>
      <div class="col-lg-9">
        <input
          class="form-control"
          type="text"
          v-model="field.help"
          :id="`form-builder-help-${index}`"
        >
      </div>
    </div>
    <div class="form-group row mt-2">
      <label
        class="col-lg-3 col-form-label text-lg-right"
        :for="`form-builder-required-${index}`"
      >
        To pole jest
      </label>
      <div class="col-8 col-sm-6 col-md-3">
        <select
          class="form-control"
          :id="`form-builder-required-${index}`"
          v-model="field.required"
        >
          <option value="true">Wymagane</option>
          <option value="false">Opcjonalne</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FieldGeneric',
  props: ['field', 'index'],
};
</script>

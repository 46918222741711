<template>
  <div class="row">
    <div class="col-lg-10 mt-5">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <h3 class="card-title">Konstruktor formularzy</h3>
        </div>
        <div class="card-body">
          <draggable
            :list="form"
            class="fields"
            tag="div"
            v-bind="dragOptions"
            @start="isDragging=true"
            @end="isDragging=false"
            :move="onMove"
            drag-class="fields__item--dragging"
          >
            <transition-group
              type="transition"
              :name="!isDragging ? 'flip-list' : null"
            >
              <FormField
                class="fields__item"
                @click="element.fixed = !element.fixed"
                v-for="(element, index) in form"
                :key="`field-${index}`"
                :field="form[index]"
                :index="index"
                :types="types"
                @remove="remove"
              >
              </FormField>
            </transition-group>
          </draggable>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-6 text-lg-right">
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Dodaj
                </button>
                <div
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    class="dropdown-item"
                    v-for="(type, index) in types"
                    :key="`type-${index}`"
                    @click="add(type.value)"
                  >
                    {{ type.name }}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <button
                type="button"
                class="btn btn-primary mr-2"
              >Generuj</button>
            </div>
          </div>
        </div>
      </div>
      <code>
        <pre>{{ form }}</pre>
      </code>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import FormField from '@/components/FormField.vue';

export default {
  name: 'App',
  components: {
    FormField,
    draggable,
  },
  data () {
    return {
      isDragging: false,
      delayedDragging: false,
      drag: false,
      form: [],
      types: [
        {
          name: 'Tekst',
          value: 'text',
        },
        {
          name: 'E-mail',
          value: 'email',
        },
        {
          name: 'Liczba',
          value: 'number',
        },
        {
          name: 'Plik',
          value: 'file',
        },
        {
          name: 'Select',
          value: 'select',
        },
        {
          name: 'Checkbox',
          value: 'checkbox',
        },
        {
          name: 'Pole tekstowe',
          value: 'textarea',
        },
        {
          name: 'Blok tekstu',
          value: 'block',
        },
        {
          name: 'Odstęp',
          value: 'spacer',
        },
      ],
    };
  },
  computed: {
    finalJSON () {
      return JSON.stringify(this.form);
    },
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        ghostClass: 'ghost',
        disabled: false,
        dragClass: 'fields__item--dragging',
      };
    },
  },
  methods: {
    add (type) {
      switch (type) {
        case 'spacer':
          this.form.push({
            type: 'spacer',
          });
          break;
        case 'block':
          this.form.push({
            type: 'block',
            heading: '',
            text: '',
          });
          break;
        case 'select':
          this.form.push({
            label: '',
            type,
            required: true,
            help: '',
            options: [''],
          });
          break;
        default: {
          this.form.push({
            label: '',
            type,
            required: true,
            help: '',
          });
          break;
        }
      }
    },
    remove (index) {
      this.form.splice(index, 1);
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
};
</script>

<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.3;
}

.fields {

  &__item {
    cursor: move;

    & + & {
      margin-top: 1rem;
    }

    &--dragging {
      border: 2px dashed var(--primary);
    }
  }
}

</style>

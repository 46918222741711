import Vue from 'vue';
import VueRouter from 'vue-router';
import QueryBuilder from '../views/QueryBuilder.vue';
import FormBuilder from '../views/FormBuilder.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/query-builder',
    name: 'QueryBuilder',
    component: QueryBuilder,
  },
  {
    path: '/form-builder',
    name: 'FormBuilder',
    component: FormBuilder,
  },
];

const router = new VueRouter({
  routes,
});

export default router;

<template>
  <div>
    <slot></slot>
    <div class="form-group row">
      <label
        class="col-lg-3 col-form-label text-lg-right"
        :for="`form-builder-heading-${index}`"
      >
        Nagłówek
      </label>
      <div class="col-lg-9">
        <input
          class="form-control"
          type="text"
          v-model="field.heading"
          :id="`form-builder-heading-${index}`"
        >
      </div>
    </div>
    <div class="form-group row mt-2">
      <label
        class="col-lg-3 col-form-label text-lg-right"
        :for="`form-builder-text-${index}`"
      >
        Tekst
      </label>
      <div class="col-lg-9">
        <textarea
          class="form-control"
          rows="4"
          v-model="field.text"
          :id="`form-builder-text-${index}`"
        >
        </textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextBlock',
  props: ['field', 'index'],
};
</script>

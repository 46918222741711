<template>
  <div>
    <div class="bg-light-primary p-3 my-3">
      <div class="form-group row mb-0">
        <div class="col-lg-4">
          <select class="form-control" v-model="query.field">
            <option value="role">Rola</option>
            <option value="region">Markoregion</option>
            <option value="club">Klub</option>

            <optgroup label="Należy do">
              <option
                v-for="(item, index) in groups.belongs"
                :key="`gb-${index}`"
                :value="`belongs-${index}`"
                >
                Należy do {{ item }}
              </option>
            </optgroup>

            <optgroup label="Wybierz użytkownika">
              <option
                v-for="(item, index) in groups.user"
                :key="`gu-${index}`"
                :value="`user-${index}`"
                >
                Użytkownik {{ item }}
              </option>
            </optgroup>

          </select>
        </div>
        <div class="col-lg-3">
          <select class="form-control" v-model="query.operator">
            <option value="equal">jest równe</option>
            <option value="notequal">jest inne niż</option>
          </select>
        </div>
        <div class="col-lg-4">
          <v-select
            class="form-control"
            :loading="loading"
            label="name"
            :filterable="true"
            :options="options"
            v-model="query.value"
            :resetOnOptionsChange="true"
          >
            <template slot="no-options">
              wyszukiwanie użytkowników...
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.name }}
                </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.name }}
              </div>
            </template>
          </v-select>
        </div>
        <div class="col-lg-1">
          <button
            type="button"
            @click="remove"
            class="btn btn-icon btn-danger btn-sm"
          >
            <i class="flaticon2-trash"></i>
          </button>
        </div>
        <div class="ml-3 mt-2 form-group" v-if="showCheck">
          <div class="check custom-control custom-checkbox">
            <input
              class="custom-control-input"
              v-model="query.allnested"
              type="checkbox"
              name="nested"
              :id="`group${index}`">
            <label
              class="custom-control-label"
              :for="`group${index}`">
                uwzględnij wszystkich poniżej w strukturze
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex p-3 ml-5 border">
      <button class="btn btn-light text-primary" @click="addRule">Dodaj regułę</button>
    </div>

    <Rule
      v-for="(rule, ruleIndex) in query.rules"
      :key="`rule-${ruleIndex}`"
      :query="query.rules[ruleIndex]"
      :index="ruleIndex"
      :groupIndex="index"
      @remove="removeRule"
    />
  </div>
</template>

<script>
import Rule from '@/components/Rule.vue';

export default {
  name: 'Group',
  components: {
    Rule,
  },
  props: [
    'query',
    'index',
  ],
  data() {
    return {
      options: [],
      showCheck: false,
      loading: false,
      groups: {
        roles: [
          'DM',
          'RMS',
          'RKS',
          'Agent niesieciowy',
          'Agent sieciowy',
          'Agent TOP',
          'OWCA niesieciowa',
          'OWCA sieciowa',
        ],
        regions: [
          'Warszawa',
          'Łódź',
        ],
        clubs: [
          'Aktywności HDI',
          'Klub Elitarni',
          'Klub Spartan',
          'Klub Walecznych',
        ],
        belongs: [
          'DM',
          'RMS',
          'RKS',
          'Agent niesieciowy',
          'Agent sieciowy',
          'Agent TOP',
          'OWCA niesieciowa',
          'OWCA sieciowa',
        ],
        user: [
          'DM',
          'RMS',
          'RKS',
          'Agent niesieciowy',
          'Agent sieciowy',
          'Agent TOP',
          'OWCA niesieciowa',
          'OWCA sieciowa',
        ],
      },
    };
  },
  methods: {
    addRule() {
      this.query.rules.push(
        {
          operator: 'equal',
        },
      );
    },
    remove() {
      this.$emit('remove', this.index);
    },
    removeRule(ruleIndex) {
      this.$emit('removeRule', {
        ruleIndex,
        groupIndex: this.index,
      });
    },
  },
  computed: {
    category() {
      return this.query.field;
    },
  },
  watch: {
    category(newVal) {
      switch (newVal) {
        case 'role':
          this.options = this.groups.roles;
          this.showCheck = true;
          break;
        case 'region':
          this.options = this.groups.regions;
          this.showCheck = false;
          break;
        case 'club':
          this.options = this.groups.clubs;
          this.showCheck = false;
          break;
        default: {
          // const type = newVal.split('-')[0];
          this.showCheck = true;
          this.loading = true;

          fetch('https://jsonplaceholder.typicode.com/users')
            .then((response) => response.json())
            .then((json) => {
              this.options = json.map((item) => item.name);
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>

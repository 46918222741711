<template>
  <div class="row">
    <div class="col-lg-10 mt-5">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <h3 class="card-title">Konstruktor zapytań</h3>
        </div>
        <div class="card-body">
          <div class="d-flex border p-3 mb-n3">
            <button class="btn btn-light-primary" @click="addGroup">Dodaj grupę</button>
          </div>

          <Group
            v-for="(item, index) in query.rules"
            :key="`qg-${index}`"
            :query="query.rules[index]"
            :index="index"
            @remove="remove"
            @removeRule="removeRule"
          />

        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-4"></div>
            <div class="col-lg-8">
              <button type="button" class="btn btn-primary mr-2">Wyślij</button>
            </div>
          </div>
        </div>
      </div>
<code><pre>{{ query }}</pre></code>
    </div>
  </div>
</template>

<script>
import Group from '@/components/Group.vue';

export default {
  name: 'App',
  components: {
    Group,
  },
  data() {
    return {
      query: {
        rules: [
          {
            operator: 'equal',
            rules: [],
          },
        ],
      },
    };
  },
  computed: {
    finalJSON() {
      return JSON.stringify(this.query);
    },
  },
  methods: {
    addGroup() {
      this.query.rules.push(
        {
          rules: [],
          operator: 'equal',
        },
      );
    },
    remove(index) {
      this.query.rules.splice(index, 1);
    },
    removeRule(obj) {
      this.query.rules[obj.groupIndex].rules.splice(obj.ruleIndex, 1);
    },
  },
};
</script>

<style lang="scss">
  .v-select {
    padding: 0;

    .vs__dropdown-toggle {
      border: 0;
      padding: 0;
    }
  }
</style>

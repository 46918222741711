<template>
  <div>
    <div class="bg-light-primary p-3">
      <div class="row">
        <div class="col-11">
          <div v-if="field.type === 'spacer'">
            <h6 class="form-control text-center">Odstęp</h6>
          </div>
          <TextBlock
            :field="field"
            :index="index"
            v-else-if="field.type === 'block'"
          >
            <h2 class="h5">
              {{ fieldName }}
            </h2>
          </TextBlock>
          <FieldSelect
            :field="field"
            :index="index"
            v-else-if="field.type === 'select'"
          >
            <h2 class="h5">
              {{ fieldName }}
            </h2>
          </FieldSelect>
          <FieldGeneric
            :field="field"
            :index="index"
            v-else
          >
            <h2 class="h5">
              {{ fieldName }}
            </h2>
          </FieldGeneric>
        </div>
        <div class="col-1">
          <button
            type="button"
            @click="remove"
            class="btn btn-icon btn-danger btn-sm"
          >
            <i class="flaticon2-trash"></i>
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import TextBlock from '@/components/TextBlock.vue';
import FieldSelect from '@/components/FieldSelect.vue';
import FieldGeneric from '@/components/FieldGeneric.vue';

export default {
  name: 'FormField',
  components: {
    TextBlock,
    FieldSelect,
    FieldGeneric,
  },
  props: [
    'field',
    'index',
    'types',
  ],
  methods: {
    addOption () {
      this.field.options.push('');
    },
    remove () {
      this.$emit('remove', this.index);
    },
  },
  computed: {
    fieldType () {
      return this.field.type;
    },
    fieldName () {
      return this.types.filter((item) => item.value === this.fieldType)[0].name;
    },
  },
  watch: {
    fieldType (newVal) {
      switch (newVal) {
        case 'spacer':
          break;
        case 'block':
          break;
        case 'select':
          if (!this.field.options.length) {
            this.field.options = [];
            this.field.options.push('');
          }
          break;
        default: {
          this.field.options = [];
          break;
        }
      }
    },
  },
};
</script>
